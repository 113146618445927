<template>
    <div class="method-modal">
        <h1 v-html="$content('pay-method')"></h1>
        <div class="method-modal_body">
            <b-form-group>
                <b-form-radio v-model="selected" name="some-radios" value="PS">
                    {{ $content('pay-method_PS') }}
                </b-form-radio>
                <b-form-radio v-model="selected" name="some-radios" value="Wallet">
                    {{ $content('pay-method_Balance') }}
                </b-form-radio>
            </b-form-group>
        </div>
        <ProjectButton @click.native="confirm" variant="app">
            <div v-html="$content('pay-method_confirm')"></div>
        </ProjectButton>
    </div>
</template>

<script>
import ProjectButton from "../Buttons/ProjectButton";
export default {
    name: "PayMethod",
    components: {ProjectButton},
    data() {
        return {
            selected: 'PS'
        }
    },
    methods: {
        confirm() {
            this.$root.$emit('methodSelected', {method: this.selected, id: this.$store.getters.activeModalParams.id});
        }
    }
}
</script>

<style lang="scss">
    @import "../../styles/mixins";

    .method-modal {
        background: white;
        width: 400px;
        border-radius: 10px;
        padding: 35px;

        @include only-xs {
            width: 100%;
        }

        &_body {
            font-family: Montserrat-Regular, sans-serif;
            margin: 35px 0 25px 0;
        }

        & h1 {
            font-family: Montserrat-Medium, sans-serif;
            font-size: 24px;
            color: var(--main-dark);
            text-align: center;
            margin-bottom: 0;
        }
    }
</style>